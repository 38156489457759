.videoContainer {
  overflow: hidden;
  position: relative;
  margin-top: 100vh;
  width: 100%;
  height: 810px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: content-box;

  @media screen and (max-width: 630px) {
    padding-top: 3rem;
  }

  .playIconContainer {
    cursor: pointer;
    display: flex;
    width: 106px;
    height: 106px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: absolute;
    z-index: 3;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 630px) {
      width: 62px;
      height: 62px;
    }

    .playIcon {
      width: 106px;
      height: 106px;
      flex-shrink: 0;
      stroke-width: 8px;
      stroke: white;

      @media screen and (max-width: 630px) {
        width: 62px;
        height: 62px;
        stroke-width: 6px;
        stroke: white;
      }
    }
  }

  .pauseIconContainer {
    border: 8px solid #FFF;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    width: 106px;
    height: 106px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: absolute;
    z-index: 3;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 630px) {
      width: 62px;
      height: 62px;
      border: 4px solid #FFF;
    }

    .pauseIcon {
      width: 36px;
      height: auto;
      stroke-width: 8px;
      flex-shrink: 0;
      stroke: white;

      @media screen and (max-width: 630px) {
        width: 22px;
        height: auto;
        stroke-width: 4px;
        stroke: white;
      }
    }
  }

  .coverImage {
    position: absolute;
    width: 100%;
    z-index: 2;
    left: 50%;
    transform: translate(-50%);

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      object-position: center;
      z-index: 2;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
      object-position: center;
      z-index: 2;
    }

    @media screen and (max-width: 630px) {
      height: calc(100% - 3rem);
      width: auto;
      top: 3rem;
    }
  }

  .video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    object-position: center;
    z-index: 1;

    @media screen and (max-width: 630px) {
      height: calc(100% - 3rem);
    }
  }
}
