.container {
  width: 100%;
  padding: 0 1.5rem;

  .mt1Mobile {
    @media screen and (max-width: 450px) {
      margin-top: 0;
    }
  }

  .mt03Mobile {
    margin-top: 1rem;

    @media screen and (max-width: 450px) {
      margin-top: .48rem;
    }
  }

  .mt102 {
    margin-top: 1.2rem;
  }

  .mt104 {
    margin-top: 1.4rem;
  }

  .mt08 {
    margin-top: .8rem;
  }
}

.ctaContainerMobile {
  display: none;
  text-align: center;

  @media screen and (max-width: 660px) {
    display: block;
  }

  .cta {
    color: black;
    text-align: right;
    font-family: Poppins-ExtraLight;
    font-size: clamp(1.25rem, 1.1538rem + 0.3846vw, 1.5rem);
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 48px */
    text-decoration-line: underline;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    @media (prefers-color-scheme: dark) {
      color: white;
    }

    @media screen and (max-width: 410px) {
      font-size: 15px;
    }

    @media screen and (max-width: 355px) {
      font-size: 13px;
      top: 2%;
    }

    .ctaIconContainer {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      gap: 7.059px;
      border: 1.5px solid black;
      border-radius: 100%;

      @media (prefers-color-scheme: dark) {
        border: 1.5px solid white;
      }

      @media (max-width: 425px) {
        width: 20px;
        height: 20px;
      }

      @media (max-width: 320px) {
        width: 18px;
        height: 18px;
      }

      .ctaIcon {

        @media (max-width: 425px) {
          width: 12px;
          height: auto;
        }

        @media (max-width: 320px) {
          width: 10px;
          height: auto;
        }

      }
    }
  }

}
.ctaContainer {
  display: flex;

  @media screen and (max-width: 660px) {
    display: none;
  }

  .cta {
    color: #000;
    text-align: right;
    font-family: Poppins-ExtraLight;
    font-size: clamp(1.25rem, 1.1538rem + 0.3846vw, 1.5rem);
    font-style: normal;
    font-weight: 300;
    line-height: 150%; /* 48px */
    text-decoration-line: underline;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-top: 14%;
    margin-left: auto;
    margin-right: auto;

    @media (prefers-color-scheme: dark) {
      color: white;
    }

    .ctaIconContainer {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      gap: 7.059px;
      border: 1.5px solid #000;
      border-radius: 100%;

      @media (prefers-color-scheme: dark) {
        border: 1.5px solid white;
      }
    }
  }
}

.cardFix {
  position: relative;
  top: -9%;
}
