.photoContainer {
  background: transparent;
}

.flipCard {
  position: relative;
  background-color: transparent;
  perspective: 1000px;
  width: 100%;
  height: auto;
}

.flipCardInner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.cardHover .flipCardInner {

  @media screen and (max-width: 790px){
    transform: rotateY(180deg);
  }
}

.cardHover .flipCardFront {

  @media screen and (min-width: 790px){
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .5s, opacity .5s linear;
    z-index: 2;
  }
}

.cardHover .flipCardBack {

  @media screen and (min-width: 790px){
    transform: none;
    z-index: 1;
  }
}


.flipCardFront, .flipCardBack {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipCardBack {
  top: 0;
  position: absolute;
  transform: rotateY(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContent {
  z-index: 1;
  position: absolute;
  left: 37px;
  right: 37px;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 74px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media screen and (max-width: 500px) {
    left: 0;
    right: 0;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }

  .cardNumber {
    color: #FFF;
    font-family: Helvetica;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 500px) {
      font-size: 18px;
    }

    @media screen and (max-width: 350px) {
      font-size: 14px;
    }
  }

  .cardNumberBig {
    color: #FFF;
    font-family: Helvetica;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width: 500px) {
      font-size: 30px;
    }

    @media screen and (max-width: 350px) {
      font-size: 20px;
    }
  }

  .cardModel {
    color: #FFF;
    font-family: Antro_Vectra;
    font-size: clamp(3.25rem, 2.0962rem + 2.6154vw, 4.25rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;

    @media screen and (max-width: 1024px) {
      font-size: clamp(3.25rem, 2.0962rem + 2.6154vw, 3.25rem);
    }

    @media screen and (max-width: 768px) {
      font-size: clamp(2.25rem, 2.0962rem + 1.6154vw, 2.25rem);
    }

    @media screen and (max-width: 500px) {
      font-size: clamp(0.25rem, 1.0962rem + 1.6154vw, 1.2rem);
    }

    @media screen and (max-width: 350px) {
      font-size: 18px;
    }
  }

  .cardModelBig {
    color: #FFF;
    font-family: Antro_Vectra;
    font-size: clamp(6.25rem, 2.6442rem + 14.4231vw, 8.625rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: fit-content;

    @media screen and (max-width: 1024px) {
      font-size: clamp(6.25rem, 2.6442rem + 7.4231vw, 8.625rem);
    }

    @media screen and (max-width: 768px) {
      font-size: clamp(1.25rem, 1.6442rem + 5.4231vw, 10.625rem);
    }

    @media screen and (max-width: 350px) {
      font-size: 30px;
    }
  }
}

.cardOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%);
}

.ctaBig, .cta {
  color: white;
  text-align: right;
  font-family: Poppins-ExtraLight;
  font-style: normal;
  font-weight: 300;
  line-height: 150%; /* 48px */
  text-decoration-line: underline;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  position: absolute;
  z-index: 1;

  .ctaIcon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    gap: 7.059px;
    border: 1.5px solid white;
    border-radius: 100%;

    .arrowRight {

      @media screen and (max-width: 350px) {
        width: 8px;
        height: auto;
      }
    }

    @media screen and (max-width: 350px) {
      width: 16px;
      height: 16px;
    }
  }
}

.ctaBig {
  font-size: 24px;
  bottom: 117px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    bottom: 80px;
  }

  @media screen and (max-width: 425px) {
    font-size: 16px;
    bottom: 50px;
  }

  @media screen and (max-width: 350px) {
    font-size: 12px;
    bottom: 30px;
  }
}

.cta {
  font-size: 16px;
  bottom: 80px;
}
