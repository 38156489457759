.phrase {
  position: relative;

  .phraseContainer {
    display: block;
    font-family: Antro_Vectra, Arial, serif;
    position: relative;
    padding: 0;
    margin: 0 auto;

    .quotesPhrase {
      color: #000;
      text-align: center;
      font-size: 72px;
      font-style: normal;
      line-height: 120%; /* 86.4px */

      @media (prefers-color-scheme: dark) {
        color: white;
      }

      @media screen and (max-width: 970px) {
        font-size: 64px;
      }

      @media screen and (max-width: 768px) {
        font-size: 54px;
      }
    }

    .quotesSignature {
      color: #000;
      text-align: right;
      font-size: 40px;
      font-style: normal;
      line-height: 120%; /* 48px */
      position: absolute;
      right: 0;

      @media (prefers-color-scheme: dark) {
        color: white;
      }

      @media screen and (max-width: 768px) {
        font-size: 28px;
      }

      @media screen and (max-width: 642px) {
        right: 30px;
      }

      @media screen and (max-width: 558px) {
        padding-right: 2rem;
        bottom: 10px;
      }
    }

    .firstQuote, .secondQuote, .thirdQuote, .fourthQuote {
      position: absolute;
      color: #000;
      text-align: center;
      font-size: 55.598px;
      font-style: normal;
      font-weight: 400;
      line-height: 0; /* 66.717px */

      @media (prefers-color-scheme: dark) {
        color: white;
      }

      @media screen and (max-width: 768px) {
        font-size: 50px;
      }
    }

    .firstQuote {
      transform: scaleX(-1) rotate(282deg);
      left: 0;
      top: 0;

      @media screen and (max-width: 642px) {
        left: 17%;
      }

      @media screen and (max-width: 558px) {
        left: 10%;
      }
    }

    .secondQuote {
      transform: scaleX(-1) rotate(282deg);
      left: 10px;
      top: 0;

      @media screen and (max-width: 642px) {
        left: 15%;
      }

      @media screen and (max-width: 558px) {
        left: 12%;
      }
    }

    .thirdQuote {
      transform: rotate(-61.183deg);
      right: 25%;
      top: 43%;

      @media screen and (max-width: 642px) {
        right: 19%;
      }

      @media screen and (max-width: 558px) {
        right: 20%;
        top: 44%;
      }

      @media screen and (max-width: 450px) {
        right: 19%;
        top: 60%;
      }

      @media screen and (max-width: 407px) {
        right: 30%;
        top: 56%;
      }

      @media screen and (max-width: 362px) {
        right: 17%;
        top: 66%;
      }

      @media screen and (max-width: 1024px) and (orientation: landscape) {
        right: 18%;
        top: 43%;
      }
    }

    .fourthQuote {
      transform: rotate(-61.183deg);
      right: 24%;
      top: 43%;

      @media screen and (max-width: 642px) {
        right: 21%;
      }

      @media screen and (max-width: 558px) {
        right: 18%;
        top: 44%;
      }

      @media screen and (max-width: 450px) {
        right: 21%;
        top: 60%;
      }

      @media screen and (max-width: 407px) {
        right: 32%;
        top: 56%;
      }

      @media screen and (max-width: 362px) {
        right: 19%;
        top: 66%;
      }

      @media screen and (max-width: 1024px) and (orientation: landscape) {
        right: 20%;
        top: 43%;
      }
    }
  }
}
