.footerContainer {
  width: 100%;

  .logoBig {
    width: 76px;
    height: 39px;

    @media screen and (min-width: 768px) {
      width: 158px;
      height: 82px;
    }

    @media screen and (max-width: 1024px) and (orientation: landscape) {
      width: 108px;
      height: auto;
    }

  }
  .line {
    width: 100%;
    height: 1px;
    background-color: black;
  }

  .linksContainer {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: row;
    margin-left: 8rem;

    @media screen and (min-width: 1440px) {
      margin-left: 18rem;
    }

    @media screen and (max-width: 926px) {
      margin-left: 5rem;
    }

    @media screen and (max-width: 830px) {
      margin-left: 1rem;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-left: 4rem;
    }

    @media screen and (max-width: 500px) {
      margin-left: 2rem;
    }

    @media screen and (max-width: 450px) {
      margin-left: 2.5rem;
    }

    @media screen and (max-width: 375px) {
      margin-left: 1.5rem;
    }

    @media screen and (max-width: 320px) {
      margin-left: .5rem;
    }

    .instaLink, .siteLink {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 1rem;
      color: black;
      font-family: Poppins-Light;
      font-size: clamp(1rem, 0.8077rem + 0.7692vw, 1.5rem);
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 24px */
      letter-spacing: 3.24px;

      @media (prefers-color-scheme: dark) {
        color: white;
      }

      @media screen and (max-width: 660px) {
        margin-top: 0;
        font-size: 16px;
      }
    }

    .siteLink {

      .siteIcon {
        @media screen and (max-width: 660px) {
          width: 20px;
          height: auto;
          margin-top: 0;
        }

        @media screen and (max-width: 1024px) and (orientation: landscape) {
          width: 36px;
          height: auto;
          margin-top: 0;
        }
      }
    }

    .instaLink {
      margin-left: 6rem;

      .instaIcon {
        @media screen and (max-width: 660px) {
          width: 20px;
          height: auto;
          margin-top: 0;
        }

        @media screen and (max-width: 1024px) and (orientation: landscape) {
          width: 36px;
          height: auto;
          margin-top: 0;
        }
      }

      @media screen and (max-width: 768px) {
        margin-left: 0!important;
      }

      @media screen and (max-width: 1024px) {
        margin-left: 4rem;
      }

      @media screen and (max-width: 660px) {
        margin-top: 1rem;
      }
    }
  }
  .footerCopyContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .footerCopy {
      font-family: Poppins-Light;
      flex: 1;
      margin: 51px auto;
      font-size: 16px;
      text-align: center;

      @media screen and (max-width: 768px) {
        max-width: 60%;
        text-align: center;
      }

      @media screen and (max-width: 425px) {
        max-width: 80%;
        text-align: center;
        font-size: 12px;
      }

      .privacyPolicyLink {
        line-height: 120%;
        letter-spacing: 0.32px;
        text-decoration-line: underline;
        margin-top: 25px;
        display: block;

        @media screen and (max-width: 500px){
          margin-top: 16px;
        }
      }
    }
  }
}
