.logo {
  z-index: 1;
  position: absolute;
  left: 140px;
  top: 40px;

  @media screen and (max-width: 500px) {
    left: 40px;
    top: 20px;
  }

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    width: 70px;
    left: 70px;
    top: 10px;
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    width: 70px;
    left: 40px;
    top: 8px;
  }
}
