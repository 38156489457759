.heroContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: white;

  @media (prefers-color-scheme: dark) {
      color: white;
      background-color: black;
  }
}

.blockContainer {
  width: 100%;
  max-width: 1440px;
  background-color: white;

  @media (prefers-color-scheme: dark) {
      color: white;
      background-color: black;
  }
}
