.videoContainer {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;

  .videoOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
    display: none;

    @media screen and (max-width: 820px) {
      display: block;
    }
  }

  .video {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
}
.panelMask {
  background-color: white;

  @media (prefers-color-scheme: dark) {
    color: white;
    background-color: black;
  }
}
.both { // Add a new element that houses all elements for the animaton and stack them all on top of each other like a deck of cards
  display: grid;
  z-index: 2;

  > * {
    grid-area: 1 / 1 / 2 / 2;
  }
}
.lineLeft {
  position: absolute;
  left: 40px;
  top: 40px;
  bottom: 40px;
  width: 5px;
  height: calc(100% - 80px); /* 100% altezza meno il padding superiore e inferiore */
  background-color: white;

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    left: 20px;
    top: 20px;
    bottom: 20px;
    width: 2px;
    height: calc(100% - 40px);
  }

  @media screen and (max-width: 500px) {
    left: 20px;
    top: 20px;
    bottom: 20px;
    width: 2px;
    height: calc(100% - 40px);
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    left: 20px;
    top: 20px;
    bottom: 20px;
    width: 2px;
    height: calc(100% - 40px);
  }
}
.lineRight {
  position: absolute;
  right: 40px;
  top: 40px;
  bottom: 40px;
  width: 5px;
  height: calc(100% - 80px); /* 100% altezza meno il padding superiore e inferiore */
  background-color: white;

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    right: 20px;
    top: 20px;
    bottom: 20px;
    width: 2px;
    height: calc(100% - 40px);
  }

  @media screen and (max-width: 500px) {
    right: 20px;
    top: 20px;
    bottom: 20px;
    width: 2px;
    height: calc(100% - 40px);
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    right: 20px;
    top: 20px;
    bottom: 20px;
    width: 2px;
    height: calc(100% - 40px);
  }
}
.lineBottom {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: 40px;
  height: 5px;
  width: calc(100% - 80px); /* 100% larghezza meno il padding superiore e inferiore */
  background-color: white;

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 2px;
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 500px) {
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 2px;
    width: calc(100% - 40px);
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    left: 20px;
    right: 20px;
    bottom: 20px;
    height: 2px;
    width: calc(100% - 40px);
  }
}
.lineTop {
  position: absolute;
  right: 40px;
  top: 40px;
  height: 5px;
  width: calc(100% - 340px); /* 100% larghezza meno il padding superiore e inferiore */
  background-color: white;

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    right: 20px;
    top: 20px;
    left: inherit;
    height: 2px;
    width: calc(100% - 195px);
  }

  @media screen and (max-width: 500px) {
    right: 20px;
    top: 20px;
    left: inherit;
    height: 2px;
    width: calc(100% - 195px);
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    right: 20px;
    top: 20px;
    left: inherit;
    height: 2px;
    width: calc(100% - 145px);
  }
}
.lineTopLeft {
  position: absolute;
  left: 40px;
  top: 40px;
  height: 5px;
  width: 60px; /* 100% larghezza meno il padding superiore e inferiore */
  background-color: white;

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    left: 20px;
    top: 20px;
    height: 2px;
    width: 30px;
  }

  @media screen and (max-width: 500px) {
    left: 20px;
    top: 20px;
    height: 2px;
    width: 30px;
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    left: 20px;
    top: 20px;
    height: 2px;
    width: 30px;
  }
}
.panelsContainer {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .panel {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;
  }
}
.blockContent {
  position: relative;
  z-index: 2;

  @media screen and (max-height: 900px) {
    align-items: start;
    padding-top: 20rem!important;
  }

  @media screen and (max-height: 1366px) and (max-width: 1024px) {
    padding-top: 31rem!important;
    display: flex!important;
    align-items: stretch;
    flex-direction: column;
    justify-content: stretch;
  }

  @media screen and (max-height: 888px) and (max-width: 1024px) {
    padding-top: 18rem!important;
    grid-auto-rows: 1fr; /* make all rows the same height */
  }

  @media screen and (max-height: 744px) and (max-width: 1440px) {
    padding-top: 16rem!important;
  }

  @media screen and (max-height: 600px) and (max-width: 1024px) {
    padding-top: 12rem!important;
    grid-auto-rows: 1fr; /* make all rows the same height */
  }

  @media screen and (max-height: 1180px) and (max-width: 820px) {
    padding-top: 26rem!important;
    grid-auto-rows: 1fr; /* make all rows the same height */
  }

  @media screen and (max-height: 932px) and (max-width: 720px) {
    padding-top: 19rem !important;
    grid-auto-rows: 1fr;
  }

  @media screen and (max-height: 744px) and (max-width: 768px) {
    padding-top: 16rem!important;
  }

  @media screen and (max-height: 896px) and (max-width: 414px) {
    padding-top: 16rem!important;
    grid-auto-rows: auto;
  }

  @media screen and (max-height: 744px) and (max-width: 430px) {
    padding-top: 9rem!important;
    grid-auto-rows: auto;
  }

  @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    padding-top: 6rem!important;
    padding-left: 3rem!important;
    padding-right: 3rem!important;
  }

  @media screen and (max-width: 425px) and (max-height: 800px) {
    padding-top: 14rem!important;
  }

  @media screen and (max-width: 425px) and (max-height: 690px) {
    padding-top: 10rem!important;
  }

  .titleContainer {

    @media screen and (max-height: 1366px) and (max-width: 1024px) {
      flex: 1;
    }

  }

  .title {
    font-family: Poppins-Bold;
    font-size: clamp(3rem, 2.4231rem + 2.3077vw, 4.5rem);
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 9.72px;
    text-transform: uppercase;
    color: white;
    width: fit-content;
    cursor: none;

    .counter {
      width: 130px;
      display: inline-block;

      @media screen and (max-width: 1024px) {
        width: 100px;
      }

      @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
        width: 60px;
      }
    }

    @media screen and (max-width: 410px) {
      letter-spacing: 4.72px!important;
    }

    @media screen and (max-width: 355px) {
      font-size: 39px;
      letter-spacing: 4.72px!important;
    }

    @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
      font-size:  30px;
    }

    &.thin {
      font-family: Poppins-Light;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: 9.72px;
      text-transform: uppercase;
    }
  }

  .contentContainer {

    @media screen and (max-height: 1366px) and (max-width: 1024px) {
      grid-column: span 12 / span 12;
      display: flex;
      flex: 1;
    }

    @media screen and (max-height: 1368px) and (max-width: 912px) {
      grid-column: span 12 / span 12;
      display: flex;
    }

    @media screen and (max-width: 820px) {
      grid-column: span 12 / span 12;
      display: flex;
      align-items: center;
    }

    @media screen and (max-height: 740px) {
      align-items: start;
    }

    @media screen and (max-height: 667px) {
      align-items: start;
    }

    @media screen and (max-width: 425px) {
      padding-top: 2rem;
    }

    @media screen and (max-width: 375px) {
      padding-top: 2rem;
    }

    .content {
      font-family: Poppins-ExtraLight;
      color: white;
      font-size: clamp(1.25rem, 1.1538rem + 0.3846vw, 1.5rem);
      font-style: normal;
      font-weight: 300;
      line-height: 150%;
      max-width: 480px;

      @media screen and (max-height: 1366px) and (max-width: 1024px) {
        display: flex;
      }

      @media screen and (max-width: 410px) {
        font-size: 18px;
      }

      @media screen and (max-width: 320px) {
        font-size: 15px;
      }

      @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
        font-size: 16px;
      }
    }
  }

  .ctaContainer {
    position: relative;

    @media screen and (max-height: 1366px) and (max-width: 1024px) {
      grid-column: span 12 / span 12;
      display: flex;
      flex: 1;
    }

    @media screen and (max-width: 820px) {
      grid-column: span 12 / span 12;
    }

    .cta {
      color: white;
      text-align: right;
      font-family: Poppins-ExtraLight;
      font-size: 16px;
      font-style: normal;
      line-height: 150%; /* 24px */
      text-decoration-line: underline;
      display: flex;
      float: right;
      justify-content: center;
      align-items: center;

      @media (prefers-color-scheme: dark) {
        color: white!important;
      }

      @media screen and (max-height: 1366px) and (max-width: 1024px) {
        float: left;
        padding-top: 0;
        position: absolute;
        top: 0;
      }

      @media screen and (max-height: 810px) and (max-width: 1024px) {
        float: left;
        padding-top: 0;
        position: absolute;
        top: 5%;
      }

      @media screen and (max-height: 600px) and (max-width: 1024px) {
        position: absolute;
        top: 6%;
      }

      @media screen and (max-height: 744px) and (max-width: 768px) {
        padding-top: 0!important;
      }

      @media screen and (max-height: 932px) and (max-width: 720px) {
        position: absolute;
        top: 15%;
      }

      @media screen and (max-height: 896px) and (max-width: 414px) {
        position: absolute;
        top: 10%;
      }

      @media screen and (max-height: 744px) and (max-width: 430px) {
        position: absolute;
        top: 20%;
      }

      @media screen and (max-width: 820px) {
        float: left;
      }

      @media screen and (max-width: 410px) {
        font-size: 15px;
      }

      @media screen and (max-width: 355px) {
        font-size: 13px;
        top: 2%;
      }

      @media screen and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
        top: 2%;
      }

      .ctaIcon {
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        align-items: center;
        gap: 7.059px;
        border: 1.5px solid white;
        border-radius: 100%;
        margin-left: -8px;
        margin-right: 8px;

        @media (prefers-color-scheme: dark) {
          border: 1.5px solid white!important;
        }

        @media screen and (max-height: 1366px) and (max-width: 1024px) {
          margin-left: 0;
        }

        @media screen and (max-width: 425px) {
          margin-left: auto;
        }
      }
    }
  }
}
.panelAlt { // The purple panel that has a diffrent styling
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);

  .lineLeft,
  .lineRight,
  .lineBottom,
  .lineTop,
  .lineTopLeft {
    background-color: black;

    @media (prefers-color-scheme: dark) {
      background-color: white;
    }
  }

  .title, .content {
    color: black;

    @media (prefers-color-scheme: dark) {
      color: white;
    }
  }

  .contentContainer {

    .content {
      color: black;

      @media (prefers-color-scheme: dark) {
        color: white;
      }
    }

  }

  .ctaContainer {
    .cta {
      color: black;

      .ctaIcon {
        border: 1.5px solid black;
      }
    }
  }

}

.textContainer {
  position: relative;
  width: fit-content;
  cursor: none;
}

.hoverImage {
  position: fixed;
  width: 150px; /* Dimensione dell'immagine */
  height: auto;
  opacity: 1;
  z-index: 2;
  pointer-events: none;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
